/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-down-sml': {
    width: 16,
    height: 16,
    viewBox: '0 0 9 5',
    data: '<g data-name="Layer 2"><path pid="0" fill="none" stroke="#c1cfd4" stroke-linecap="round" stroke-linejoin="round" d="M1 1l3.5 3.5L8 1" data-name="Layer 1"/></g>',
    lastMod: '1741690914655.683'
  }
})
