/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button-polygon': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 12',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" stroke="currentColor" stroke-miterlimit="10"><path pid="0" fill="none" d="M4.5 1.5l-3 5 5 4 4-7-6-2z"/><circle pid="1" cx="4.5" cy="1.5" r="1" fill="#e8f9ff"/><circle pid="2" cx="10.5" cy="3.5" r="1" fill="#e8f9ff"/><circle pid="3" cx="6.5" cy="10.5" r="1" fill="#e8f9ff"/><circle pid="4" cx="1.5" cy="6.5" r="1" fill="#e8f9ff"/></g></g>',
    lastMod: '1741690914655.683'
  }
})
