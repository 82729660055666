/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'car-outline': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 26',
    data: '<path pid="0" d="M18.9 6c-.2-.6-.8-1-1.4-1h-11c-.7 0-1.2.4-1.4 1L3 12v8c0 .5.5 1 1 1h1c.6 0 1-.5 1-1v-1h12v1c0 .5.5 1 1 1h1c.5 0 1-.5 1-1v-8l-2.1-6M6.8 7h10.3l1.1 3H5.8l1-3M19 17H5v-5h14v5M7.5 13c.8 0 1.5.7 1.5 1.5S8.3 16 7.5 16 6 15.3 6 14.5 6.7 13 7.5 13m9 0c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5z"/>',
    lastMod: '1741690914655.683'
  }
})
