/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'weight': {
    width: 20,
    height: 20,
    viewBox: '1 2 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M11 3a4 4 0 014 4c0 .73-.19 1.41-.54 2H17c.95 0 1.75.67 1.95 1.56C20.96 18.57 21 18.78 21 19a2 2 0 01-2 2H3a2 2 0 01-2-2c0-.22.04-.43 2.05-8.44C3.25 9.67 4.05 9 5 9h2.54A3.89 3.89 0 017 7a4 4 0 014-4m0 2a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2z" fill="#1a1a1a"/>',
    lastMod: '1741690914658.683'
  }
})
