/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'notifications-danger': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M10 18.333a1.672 1.672 0 001.667-1.667H8.333A1.672 1.672 0 0010 18.333zm5-5V9.166c0-2.558-1.358-4.7-3.75-5.267v-.566a1.25 1.25 0 00-2.5 0V3.9A5.071 5.071 0 005 9.167v4.166L3.333 15v.833h13.333V15zm-1.667-4.167z" fill="#be3939"/>',
    lastMod: '1741690914657.683'
  }
})
