/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close_x': {
    width: 19.467,
    height: 19.467,
    viewBox: '0 0 19.467 19.467',
    data: '<g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.733 18.071l18-16.675M.733 1.397l18 16.675"/></g>',
    lastMod: '1741690914656.683'
  }
})
