/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'line-dot': {
    width: 16,
    height: 16,
    viewBox: '0 0 18 2',
    data: '<circle pid="0" cx="1.5" cy="1" r="1.5" fill="currentColor"/><circle pid="1" cx="6" cy="1" r="1.5" fill="currentColor"/><circle pid="2" cx="10.5" cy="1" r="1.5" fill="currentColor"/>',
    lastMod: '1741690914656.683'
  }
})
