/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'loggers': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M20.5 2.043h-17a1 1 0 00-1 1v17.914a1 1 0 001 1h17a1 1 0 001-1V3.043a1 1 0 00-1-1zm-1 17.914h-15V4.043h15z" fill="#737373"/><path pid="2" d="M8.451 17.438a1.133 1.133 0 10-1.134-1.133 1.134 1.134 0 001.134 1.133zM14.317 16.305a1.134 1.134 0 101.134-1.134 1.134 1.134 0 00-1.134 1.134zM12.001 13.444a3.8 3.8 0 003.436-5.41 3.814 3.814 0 00-3.438-2.184 3.8 3.8 0 00-3.436 5.411 3.814 3.814 0 003.438 2.183zm-.871-5.649a2.046 2.046 0 11-.983 2.722 2.039 2.039 0 01.983-2.722z" fill="#737373"/>',
    lastMod: '1741690914656.683'
  }
})
