/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button-upload': {
    width: 16,
    height: 16,
    viewBox: '0 0 11 12',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.5 3.25V2.5a2 2 0 012-2h6a2 2 0 012 2v.75M5.5 11.5v-7M8 7L5.5 4.5M3 7l2.5-2.5"/></g></g>',
    lastMod: '1741690914655.683'
  }
})
