/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sunny': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M5.713 4.056L4.24 2.593 3.086 3.746l1.465 1.463zM1 8.683h2.455v1.635H1zM9.182.55h1.636v2.411H9.182zm6.578 2.039l1.152 1.15L15.447 5.2 14.3 4.052zm-1.473 12.355l1.465 1.471 1.154-1.152-1.473-1.463zm2.258-6.261H19v1.635h-2.455zM10 4.6a4.9 4.9 0 104.909 4.9A4.91 4.91 0 0010 4.6zm0 8.174A3.269 3.269 0 1113.273 9.5 3.27 3.27 0 0110 12.769zm-.818 3.269h1.636v2.407H9.182zm-6.1-.785l1.158 1.149 1.46-1.472-1.149-1.152z" fill="#1a1a1a"/>',
    lastMod: '1741690914658.683'
  }
})
