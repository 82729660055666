/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alert-comment': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M17.992 3.6A1.6 1.6 0 0016.4 2H3.6A1.6 1.6 0 002 3.6v9.6a1.6 1.6 0 001.6 1.6h11.2L18 18zm-1.592 0v10.536l-.936-.936H3.6V3.6zM5.2 10h9.6v1.6H5.2zm0-2.4h9.6v1.6H5.2zm0-2.4h9.6v1.6H5.2z" fill="#009bd4"/>',
    lastMod: '1741690914655.683'
  }
})
