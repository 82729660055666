/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'router': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/><path pid="1" d="M16 4.2a6.254 6.254 0 014.2 1.7l.8-.8a7 7 0 00-10 0l.8.8A6.254 6.254 0 0116 4.2zm-3.3 2.5l.8.8a3.625 3.625 0 015 0l.8-.8a4.589 4.589 0 00-6.6 0zM19 13h-2V9h-2v4H5a2.006 2.006 0 00-2 2v4a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2v-4a2.006 2.006 0 00-2-2zm0 6H5v-4h14zM6 16h2v2H6zm3.5 0h2v2h-2zm3.5 0h2v2h-2z" fill="#009bd4"/>',
    lastMod: '1741690914657.683'
  }
})
