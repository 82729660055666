/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'window-state': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><g transform="translate(4.085 1.625)" stroke-miterlimit="10" stroke="#1a1a1a" fill="#1a1a1a"><rect pid="1" width="15.67" height="16" rx="1.499" stroke="none"/><rect pid="2" x=".5" y=".5" width="14.67" height="15" rx=".999" fill="none"/></g><g fill="#fff" stroke-miterlimit="10"><path pid="3" d="M18.199 16.626H5.671c-.313 0-.512-.132-.557-.197v-.11l-.034-.127L2.032 4.844c.079-.069.262-.162.525-.162h12.528c.312 0 .511.131.556.196v.11l.034.127 3.048 11.349c-.078.068-.26.162-.524.162z"/><path pid="4" d="M17.462 15.626l-2.67-9.944h-11.5l2.67 9.944h11.5m.737 2H5.671c-.86 0-1.557-.526-1.557-1.175L1 4.855c0-.648.698-1.173 1.557-1.173h12.528c.86 0 1.556.525 1.556 1.173l3.114 11.596c0 .65-.697 1.175-1.556 1.175z" fill="#1a1a1a"/></g>',
    lastMod: '1741690914658.683'
  }
})
