/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button-preview-mobile': {
    width: 16,
    height: 16,
    viewBox: '0 0 15 15',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="#c1cfd4" stroke-miterlimit="10"><path pid="0" stroke-linecap="round" d="M14.5 14.5L10 10"/><circle pid="1" cx="6" cy="6" r="5.5"/></g></g>',
    lastMod: '1741690914655.683'
  }
})
