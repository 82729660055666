/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'liquid': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M14 13.793a4.96 4.96 0 00-2.36.608 3.391 3.391 0 01-1.64.456 3.166 3.166 0 01-1.64-.457 4.883 4.883 0 00-4.72 0 3.366 3.366 0 01-1.64.458v1.482a4.96 4.96 0 002.36-.608A3.366 3.366 0 016 15.276a3.211 3.211 0 011.64.456 4.883 4.883 0 004.72 0 3.391 3.391 0 011.64-.456 3.166 3.166 0 011.64.456 4.907 4.907 0 002.36.608v-1.482a3.166 3.166 0 01-1.64-.456 4.96 4.96 0 00-2.36-.609zm0-3.383a5.05 5.05 0 00-2.36.608 3.477 3.477 0 01-1.64.456 3.166 3.166 0 01-1.64-.456A4.881 4.881 0 006 10.411a4.968 4.968 0 00-2.36.608 3.45 3.45 0 01-1.64.456v1.482a5.05 5.05 0 002.36-.608 3.176 3.176 0 013.28 0 4.881 4.881 0 002.36.608 4.968 4.968 0 002.36-.608 3.176 3.176 0 013.28 0 4.907 4.907 0 002.36.608v-1.482a3.166 3.166 0 01-1.64-.456 4.96 4.96 0 00-2.36-.608zm2.36-6.142a4.883 4.883 0 00-4.72 0 3.477 3.477 0 01-1.64.456 3.166 3.166 0 01-1.64-.456A4.968 4.968 0 006 3.66a4.881 4.881 0 00-2.36.608A3.366 3.366 0 012 4.724v1.467a5.05 5.05 0 002.36-.608A3.366 3.366 0 016 5.127a3.211 3.211 0 011.64.456 4.881 4.881 0 002.36.608 4.968 4.968 0 002.36-.608A3.477 3.477 0 0114 5.127a3.166 3.166 0 011.64.456 4.907 4.907 0 002.36.608V4.709a3.313 3.313 0 01-1.64-.441zM14 7.028a5.05 5.05 0 00-2.36.608 3.176 3.176 0 01-3.28 0A4.881 4.881 0 006 7.028a4.968 4.968 0 00-2.36.608A3.166 3.166 0 012 8.092v1.482a5.05 5.05 0 002.36-.608A3.477 3.477 0 016 8.51a3.234 3.234 0 011.64.456 4.881 4.881 0 002.36.608 4.968 4.968 0 002.36-.608A3.477 3.477 0 0114 8.51a3.166 3.166 0 011.64.456 4.907 4.907 0 002.36.608V8.092a3.166 3.166 0 01-1.64-.456A4.96 4.96 0 0014 7.028z" fill="#1a1a1a"/>',
    lastMod: '1741690914656.683'
  }
})
