/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'humidity': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M6.125 16A4.247 4.247 0 012 11.49Q2 8.751 6.125 5q4.125 3.754 4.125 6.49A4.247 4.247 0 016.125 16zM13.647 9.059A2.725 2.725 0 0111 6.165Q11 4.407 13.647 2q2.647 2.409 2.647 4.165a2.725 2.725 0 01-2.647 2.894zM15.412 17a2.725 2.725 0 01-2.647-2.894q0-1.758 2.647-4.165 2.647 2.409 2.647 4.165A2.725 2.725 0 0115.412 17z" fill="#1a1a1a"/>',
    lastMod: '1741690914656.683'
  }
})
